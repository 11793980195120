.td-column-function-even-example{
    font-weight: bold;
    color: #d11e1e;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 13px;
  display: inline-block;
  padding: 6px;
  margin: 0 5px 5px 5px;
  cursor: default !important;
  border-radius: 2px;
}
div.ReactTags__selected button.ReactTags__remove {
  color: #9c9c9c;
  margin-left: 5px;
  cursor: pointer;
  border: none !important;
}
div.ReactTags__remove {
  margin-left: 10px;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.reply-btn{
  cursor: pointer;
  color: #20a5d6 !important;
}
.badge-received{
  color:#fff;
  background-color:#ea4b5b
}
.badge-assigned{
  color:#fff;
  background-color:#20a5d6
}
.badge-closed{
  color:#fff;
  background-color:#57686d
}
.badge-font-padding{
  padding: 0.7em;
  font-size: 90%;
}